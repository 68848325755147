<template>
  <div class="pb-3"><h1>SCARICA PDF</h1></div>
  <!-- FUTURI FILE -->
  <a
    v-if="1 == 2"
    href="/media/fit-media/Documenti/FAQ - Obblighi D. Lgs. 39-2021.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">FAQ - Obblighi D. Lgs. 39-2021</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    v-if="1 == 2"
    href="/media/fit-media/Documenti/Tutorial caricamento Nomina Resp Mod e Cod v8def 1.pptx"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">Tutorial caricamento Nomina Resp Mod e Cod</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <!-- documenti -->
  <a
    href="/media/fit-media/Documenti/FITP_ Form per la redazione del Modello di Organizzazione ex D.lgs. 39.2021_v6def_2.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          Modello di organizzazione e controllo ex D.lgs. 39/2021
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/FITP_ Form per la redazione del Codice di Condotta_v6def_2.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">Codice di Condotta ex D.lgs. 39/2021</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Consensi informativa privacy tessermanto online.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Consensi informativa privacy tessermanto online
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Designazione del Responsabile del trattamento - Affiliazione.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Designazione del Responsabile del trattamento - Affiliazione
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Designazione del Responsabile del trattamento - Aggregazione.docx"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Designazione del Responsabile del trattamento - Aggregazione
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Informativa tesseramento online atleta agonista_.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Informativa tesseramento online atleta agonista
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Modulo Affiliazione-Riaffiliazione.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">2024 Modulo Affiliazione-Riaffiliazione</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Modulo rinnovo tesseramento cartaceo MINORI 14 ANNI COMPIUTI.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Modulo rinnovo tesseramento cartaceo MINORI 14 ANNI COMPIUTI
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Modulo tesseramento cartaceo socio e atleta non agonista.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Modulo tesseramento cartaceo socio e atleta non agonista
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Modulo transexFM + Informativa Privacy.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">2024 Modulo transexFM + Informativa Privacy</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Modulo transexMF non op + Informativa Privacy.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">
          2024 Modulo transexMF non op + Informativa Privacy
        </div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Modulo transexMF op + Informativa Privacy.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">2024 Modulo transexMF op + Informativa Privacy</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Trasferimento_maggiorenni.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">2024 Trasferimento maggiorenni</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
  <a
    href="/media/fit-media/Documenti/2024 Trasferimento_minorenni.pdf"
    target="_blank"
    class="text-dark text-hover-primary"
  >
    <div class="card shadow p-4 my-2">
      <div class="d-flex justify-content-between">
        <div class="fs-6">2024 Trasferimento minorenni</div>
        <i class="bi bi-download text-dark fw-bold fs-2"> </i>
      </div>
    </div>
  </a>
</template>

<script>
import { defineComponent, onMounted } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "moduli",

  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Modulistica", []);
    });

    return {};
  },
});
</script>
